@import '../../utilities/variables';

@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@600&display=swap');

.readMoreButton:hover{
    background-color: $principal-color;
    color: white;
    text-decoration: none;
}

.principal{
    top: 0;
    left: 0;
    height: 100%;
    padding: 40px 0px 0px 10px;
    margin-top: 120px;
}

.initialInfo{
    font-family: $principal-font;
    color: $principal-color;
    font-size: 32px;
    text-align: left;
}

@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab&display=swap');
.descriptionInfo{
    font-size: 22px;
    font-family: $principal-font;
    text-align: left;
    color: #636363;
}


.icon{
    color: $principal-color;
    margin: 70px;
    font-family: 'Roboto Mono', monospace;
}

.icons{
    margin:auto;
    width: 70%;
    padding: 10px;
}

.icon-image{
    margin-left: auto;
    margin-right: auto;
    display: block;
    width: 100px;
}


@media (max-width: 1024px){
    .icons{
        padding: 0px;
        width: 100%;
        margin-left: 14px;
    }

    .icon{
        font-size: 16px;
        margin-left: 10px;
        margin-right: 80px;
        margin-bottom: 0px;
        width: 80px;
        text-align: center;
    }

    .icon-image{
        width: 80px;
    }
    
    .initialInfo{
        font-size: 26px;
        text-align: left;
    }

    .descriptionInfo{
        font-size: 20px;
        text-align: left;
    }

    .principal{
        top: 0;
        left: 0;
        height: 100%;
        padding: 20px;
        margin-top: 10px;
    }

  }


.webDeveloper{
    color: $principal-color;
}