@import '../../utilities/variables';

.technology{
    margin: 0px 20px 30px 0px;
    text-align: center;
}

.technology-name{
    font-size: 18px;
    font-family: $principal-font;
    color: $principal-color;
    margin: 0px;
}

@media (max-width: 769px) {
    .technology{
        margin: 0px;
        padding: 6px;
    }
}